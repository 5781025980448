.App {
  /* min-height: calc(100vh - 56px); */
  min-height: 100vh;
  background: #f8f9fa;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.card {
  padding: 2em 3em;
}

button.btn {
  width: 20%;
  margin: 0 0.5em;
}

@media only screen and (max-width: 480px) {
  .card {
    padding: 1em;
  }
}
