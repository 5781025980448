.InputTextField{
   width: 100%;
}
.LoadingSpinner {
   display: flex;
   flex-direction: column;
}

.LoadingSpinner h5 {
   color: #fff;
}

.Overlay {
   width: 100vw;
   height: 100vh;
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background: rgba(0, 0, 0, .7);
}
.Login,
.Registrarse {
   /* width: 100vw; */
   height: -webkit-max-content;
   height: max-content;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: url(/static/media/portada.81f9b638.jpg) no-repeat center center fixed;
   background-size: cover;
   position: relative;
}

.Login .logos,
.Registrarse .logos {
   width: -webkit-max-content;
   width: max-content;
   margin-bottom: 1em;
}

.Login .logos img,
.Registrarse .logos img {
   width: 80px;
}

.Login .card,
.Registrarse .card {
   width: 35%;
   min-width: 400px !important;
   margin: 1em;
   padding: 1em 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: rgba(255, 255, 255, 0.30);
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
   -webkit-backdrop-filter: blur(20px);
           backdrop-filter: blur(20px);
   border: 1px solid rgba(255, 255, 255, 0.20);
}

.Login .card-title,
.Registrarse .card-title {
   font-size: 2em;
   font-weight: 900;
   color: #fff;
}

.Login .card-body,
.Registrarse .card-body {
   width: 100%;
}

.Login form,
.Registrarse form {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.Login form .form-label,
.Registrarse form .form-label {
   color: #fff;
}

.Login form button,
.Registrarse form button {
   margin-top: 1em;
}

.Login .bn-login{
   margin-left: 0;
   width: 100% !important;
}

@media only screen and (max-width: 480px) {
   .Login .card,
   .Registrarse .card {
      width: 90%;
      min-width: 200px !important;
   }

   .Login .bn-login{
      margin-left: 0;
      width: 100% !important;
   }
}
.Home .table {
  text-align: center;
  vertical-align: middle !important;
}

.Home .table tr td:nth-child(1) {
  width: 4%;
  text-align: center;
}

.Home .table tr td:nth-child(2),
.Home .table tr td:nth-child(3) {
  width: 20%;
  text-align: center;
}

.Home .table tr td:nth-child(4),
.Home .table tr td:nth-child(5) {
  width: 20%;
  text-align: center;
}

.Home .history {
  width: -webkit-max-content;
  width: max-content;
}

.Home .btn-add {
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .Home .btn-add {
    width: 100%;
    margin: 0;
  }

  .Home .table thead {
    font-size: 0.7em;
  }
}

.TituloPaginas{
   width: 100%;
   margin: 1em 0 2em;
}

.TituloPaginas h1{
   text-align: center;
   color: #009b08;
}
.IdentificacionPaciente .button-home{
   display: flex;
   justify-content: center;
   align-items: center;
}

.IdentificacionPaciente .btn{
   width: -webkit-max-content;
   width: max-content;
}
.Subtitulos{
   /* margin-bottom: 1em; */
   color: rgb(64, 175, 0);
}
.CariesDental input[type="number"].form-control {
  padding: 0.375rem 5px;
  border: 0px solid red;
  outline: none;
  text-align: center;
  background: transparent;
}

.CariesDental .card_tables {
  max-width: 100% !important;
  overflow-x: scroll !important;
}

.CariesDental .card_tables .table_info_copd.table .sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  background: #fff;
  border: 1px solid #dfdfdf;
}

.CariesDental .card_tables .table_info_copd.table {
  text-align: center;
  vertical-align: middle !important;
}

/* .CariesDental .table_info_copd.table  */

.CariesDental .table_info_copd.table tr td {
  padding: 0px 8px !important;
}

.CariesDental .table_info_copd.table thead tr:nth-child(1) {
  font-weight: 500;
}

.CariesDental .table_info_copd.table thead tr:nth-child(2) td:nth-child(n + 2) {
  color: blue;
  text-transform: uppercase;
}

.CariesDental .table_info_copd.table thead tr:nth-child(1),
.CariesDental .table_info_copd.table thead tr:nth-child(2) {
  height: 3em;
  vertical-align: middle !important;
}

.CariesDental .table_info_copd.table tr td:nth-child(1) {
  width: 70px;
  font-weight: 500;
}

.CariesDental .table_info_copd.table tr td.nullCell {
  background: #dfdfdf;
}

.CariesDental td input[type="number"]:focus,
.CariesDental select:focus,
.CariesDental select,
.CariesDental input[type="number"].form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  text-align: center;
}

.CariesDental .infoButton {
  margin: 8px;
  width: 20px !important;
  height: 20px !important;
}

.CariesDental .infoButton:hover {
  color: blue;
}

.CariesDental .card-copd {
  width: 50%;
  margin: 0 auto;
}

.CariesDental .card-copd h5 {
  text-align: center;
  color: #009b08;
  font-weight: 900;
}

.CariesDental .table_leyenda,
.CariesDental .card-copd {
  text-align: center;
}

.CariesDental .table_leyenda thead,
.CariesDental .table_info_copd thead,
.CariesDental .card-copd thead {
  font-weight: 600;
}

.CariesDental .table_leyenda thead tr:nth-child(1),
.CariesDental .table_info_copd thead tr:nth-child(1),
.CariesDental .card-copd thead tr:nth-child(1) {
  background: #00796f31;
}

.CariesDental .table_leyenda thead tr:nth-child(n + 2) {
  background: #4ac0b62a;
}

.CariesDental .table_leyenda.Car tbody tr td:nth-child(1) {
  width: 50%;
}

.CariesDental .table_leyenda.Car tbody tr td:nth-child(2),
.CariesDental .table_leyenda.Car tbody tr td:nth-child(3) {
  width: 25%;
}

@media only screen and (max-width: 480px) {
  .CariesDental .card-copd {
    width: 100%;
    margin: 0 auto;
  }
}

.HistoriaClinica .antecedentes {
  border-left: 3px solid #3d8314;
}

.HistoriaClinica .seccionAntecedentes .row .col-md-6:nth-child(1) {
  display: flex;
  align-items: center;
}

.tab-content > .active {
  background: #fff;
  padding: 1em 2em;
  border-right: 1px solid #0000002d;
  border-bottom: 1px solid #0000002d;
  border-left: 1px solid #0000002d;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.HistoriaClinica .table input[type="number"] {
  text-align: center;
}

.BottonSubmit {
  width: 100%;
  margin-top: 1em !important;
}

.row.BottonSubmit {
  justify-content: center;
}

.BottonSubmit .btns {
  text-align: center;
}

.BottonSubmit .btn {
  width: 250px;
}

@media only screen and (max-width: 480px) {
  .BottonSubmit .btns {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}


.Consentimiento .leyenda {
   margin: 0;
}
.InputCheck {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

 .Antecedentes .col{
   display: flex;
   align-items: center;
 }

 .Antecedentes .card{
  width: 100%;
 }
.EvaluacionSaludBucal ul{
   margin: 0;
}

.EvaluacionSaludBucal .clasificar .form-check-label{
   font-size: 1.1em;
   font-weight: 700;
}
.PlacaBacteriana .card.center {
  text-align: center;
}

#root > div > div.PlacaBacteriana > div > form > div.Observaciones.card {
  text-align: left;
}

.PlacaBacteriana .placa input[type="number"].form-control {
  padding: 0.375rem 5px;
  border: 0px solid red;
  outline: none;
  text-align: center;
  background: transparent;
}

.PlacaBacteriana .placa table tr td {
  padding: 0px !important;
}

.table > :not(caption) > * > * {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.PlacaBacteriana .infoButton {
  margin: 8px;
  width: 20px !important;
  height: 20px !important;
}

.PlacaBacteriana .placa td input[type="number"]:focus,
.PlacaBacteriana .placa select:focus,
.PlacaBacteriana .placa select,
.PlacaBacteriana .placa input[type="number"].form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  text-align: center;
}

.PlacaBacteriana .calculos {
  padding: 1px;
  margin: 5px 0;
}

.PlacaBacteriana .calculos h1 {
  margin: 0px;
  color: #009b08;
}

.PlacaBacteriana .table_leyenda {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.PlacaBacteriana .table_leyenda .tituloTable {
  color: #212529;
  text-align: center;
  font-weight: 500;
  background-color: #00796f31 !important;
}

.PlacaBacteriana .table_leyenda td {
  padding: 0 1em !important;
  vertical-align: middle !important;
}

.PlacaBacteriana .table_leyenda td.td_grado {
  width: 5em;
  text-align: center;
}

.PlacaBacteriana .table_leyenda .subtituloTable {
  padding: 0 1em;
  color: #212529;
  text-align: center;
  font-weight: 500;
  background-color: #4ac0b62a !important;
}
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-3
  > table,
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-4
  > table:nth-child(1),
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-4
  > table:nth-child(2),
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-5
  > table {
  text-align: center !important;
}

body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-3
  > table
  > thead,
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-4
  > table:nth-child(1)
  > thead,
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-4
  > table:nth-child(2)
  > thead,
body
  > div.fade.modal.show
  > div
  > div
  > div.modal-body
  > div
  > div.col-5
  > table
  > thead {
  font-weight: 600;
  background-color: #4ac0b62a !important;
}



.App {
  /* min-height: calc(100vh - 56px); */
  min-height: 100vh;
  background: #f8f9fa;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.card {
  padding: 2em 3em;
}

button.btn {
  width: 20%;
  margin: 0 0.5em;
}

@media only screen and (max-width: 480px) {
  .card {
    padding: 1em;
  }
}

.NavigationBar img {
  width: 50px;
  height: 50px;
}

.NavigationBar .btn-sesion{
   width: -webkit-max-content;
   width: max-content;
}

