.CariesDental input[type="number"].form-control {
  padding: 0.375rem 5px;
  border: 0px solid red;
  outline: none;
  text-align: center;
  background: transparent;
}

.CariesDental .card_tables {
  max-width: 100% !important;
  overflow-x: scroll !important;
}

.CariesDental .card_tables .table_info_copd.table .sticky {
  position: sticky;
  left: 0px;
  background: #fff;
  border: 1px solid #dfdfdf;
}

.CariesDental .card_tables .table_info_copd.table {
  text-align: center;
  vertical-align: middle !important;
}

/* .CariesDental .table_info_copd.table  */

.CariesDental .table_info_copd.table tr td {
  padding: 0px 8px !important;
}

.CariesDental .table_info_copd.table thead tr:nth-child(1) {
  font-weight: 500;
}

.CariesDental .table_info_copd.table thead tr:nth-child(2) td:nth-child(n + 2) {
  color: blue;
  text-transform: uppercase;
}

.CariesDental .table_info_copd.table thead tr:nth-child(1),
.CariesDental .table_info_copd.table thead tr:nth-child(2) {
  height: 3em;
  vertical-align: middle !important;
}

.CariesDental .table_info_copd.table tr td:nth-child(1) {
  width: 70px;
  font-weight: 500;
}

.CariesDental .table_info_copd.table tr td.nullCell {
  background: #dfdfdf;
}

.CariesDental td input[type="number"]:focus,
.CariesDental select:focus,
.CariesDental select,
.CariesDental input[type="number"].form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  text-align: center;
}

.CariesDental .infoButton {
  margin: 8px;
  width: 20px !important;
  height: 20px !important;
}

.CariesDental .infoButton:hover {
  color: blue;
}

.CariesDental .card-copd {
  width: 50%;
  margin: 0 auto;
}

.CariesDental .card-copd h5 {
  text-align: center;
  color: #009b08;
  font-weight: 900;
}

.CariesDental .table_leyenda,
.CariesDental .card-copd {
  text-align: center;
}

.CariesDental .table_leyenda thead,
.CariesDental .table_info_copd thead,
.CariesDental .card-copd thead {
  font-weight: 600;
}

.CariesDental .table_leyenda thead tr:nth-child(1),
.CariesDental .table_info_copd thead tr:nth-child(1),
.CariesDental .card-copd thead tr:nth-child(1) {
  background: #00796f31;
}

.CariesDental .table_leyenda thead tr:nth-child(n + 2) {
  background: #4ac0b62a;
}

.CariesDental .table_leyenda.Car tbody tr td:nth-child(1) {
  width: 50%;
}

.CariesDental .table_leyenda.Car tbody tr td:nth-child(2),
.CariesDental .table_leyenda.Car tbody tr td:nth-child(3) {
  width: 25%;
}

@media only screen and (max-width: 480px) {
  .CariesDental .card-copd {
    width: 100%;
    margin: 0 auto;
  }
}
